import { useEffect, useState } from "react";
import { GetRequest, PostRequest } from "../../includes/functions";
import { toast } from "react-toastify";

interface APIKeysComponentsProp {
onClose:()=>void;
}
const APIKeysComponents = (prop:APIKeysComponentsProp)=>{
   const [apiKeys,setApiKeys] = useState<{APILiveKeys:string;APITestKeys:string;}>({
        APILiveKeys:"",
        APITestKeys:""
   })
   const [loading,setLoading] = useState<boolean>(false)
   const [currentPassword,setCurrentPassword] = useState<string>("")
    const GetKeys = ()=>{
        GetRequest("get-keys",{}).then((response)=>{
          if(response.status)
          {
            setApiKeys(response.data);
          }
        })
      }
      const GenerateNewKeys = ()=>{
        setLoading(true);
        PostRequest("generate-apikeys",{currentPassword},true,true,"upload").then((response)=>{
        setLoading(false);
          if(response.status)
          {
           setApiKeys({
            APILiveKeys:response.data.ApiLIVEKey,
            APITestKeys:response.data.ApiTESTKey
           })
          }
        })
      }
      function CopyText(text:string) {
        navigator.clipboard.writeText(text);
        toast.success(`API key copied (${text}) to clipboard.`, {
            position:toast.POSITION.TOP_CENTER
          });
      }
      useEffect(()=>{
        GetKeys();
      },[])
    return <div className="modal" tabIndex={-1}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">API KEYS</h5>
          <button onClick={prop.onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
  <label  className="form-label b">Test KEY</label>
  <div className="input-group mb-3">
  <input value={apiKeys.APITestKeys} disabled type="text" className="form-control" placeholder="TEXT-xxxxxxxxxxxxxxxxxxxx" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <span 
  onClick={()=>CopyText(apiKeys.APITestKeys)}
  className="input-group-text btn btn-success" id="basic-addon2">Copy</span>
</div>
<label  className="form-label b">Live KEY</label>
<div className="input-group mb-3">
  <input value={apiKeys.APILiveKeys} disabled type="text" className="form-control" placeholder="TEXT-xxxxxxxxxxxxxxxxxxxx" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <span 
   onClick={()=>CopyText(apiKeys.APILiveKeys)}
  className="input-group-text btn btn-success" id="basic-addon2">Copy</span>
</div>
<div className="alert alert-info">
<label  className="form-label b">Generate new sets of API Keys</label>

<div className="row">
<div className="col-6 d-flex align-items-end justify-content-end">
<input  
maxLength={50}
value={currentPassword}  
type="password"
onChange={(d)=>{
setCurrentPassword(d.target.value);
}}
className="form-control " placeholder="Current password" />
</div>
<div className="col-6">
    <button 
    disabled={loading || currentPassword === ""}
    onClick={()=>{
        GenerateNewKeys()
    }}
    className="btn btn-success float-end m-0 d-flex align-items-center justify-content-center">
        <span className="me-3">Generate new keys</span>
        {loading && <div className="spinner-border text-light spinner-border-sm" role="status">
  <span className="visually-hidden">Loading...</span>
</div>}
    </button>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
}
export default APIKeysComponents;